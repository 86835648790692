import auth             from './api/actions/auth';
import courses          from './api/actions/courses';
import teaching         from './api/actions/teaching';
import teacherProfiles  from './api/actions/teacherProfiles';
import teacherPayouts   from './api/actions/teacherPayouts';
import admin            from './api/actions/admin';
import deals            from './api/actions/deals';
import knowledgebase    from './api/actions/knowledgebase';
import teacher          from './api/actions/teacher';
import glossary					from './api/actions/glossary';

export default {
	auth,
	courses,
	teaching,
	teacherProfiles,
	teacherPayouts,
	admin,
	deals,
	knowledgebase,
	teacher,
	glossary
};
