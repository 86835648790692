import { get }  from '@/services/api/methods';
import { put }  from '@/services/api/methods';
import { del }  from '@/services/api/methods';
import { post }     from '@/services/api/methods';

export default {

	getCourses: ({
		include,
		searchString,
		filters,
		approval,
		page,
		per,
	  sortBy
		// sortDir
	}) => {
		return get('/admin/courses', {
			options: {
				include,
				searchString,
				approval,
				filters,
				// sortDir,
				page,
				per,
				sortBy
			}
		});
	},

	// courses

	getCourseById: ({ courseId }) => {
		return get(`/admin/courses/${courseId}`);
	},

	approvePublicationByCourseById: ({
		courseId,
		course = { approvalNote: '' }
	}) => {
		return post(`/admin/courses/${courseId}/approve`, {
			requestData: course
		});
	},

	rejectPublicationByCourseById: ({
		courseId,
		course = { approvalNote: '' }
	}) => {
		return post(`/admin/courses/${courseId}/reject`, {
			requestData: course
		});
	},

	requestPublicationByCourseId: ({ courseId }) => {
		return post(`/admin/courses/${courseId}/publish`);
	},

	unpublishByCourseId: ({ courseId }) => {
		return del(`/admin/courses/${courseId}/publish`);
	},

	updateCourseById: ({ course, courseId }) => {
		return put(`/admin/courses/${courseId}`, {
			requestData: course
		});
	},

	deleteByCourseId: ({ courseId }) => {
		return del(`/admin/courses/${courseId}`);
	},

	unDeleteByCourseId: ({ courseId }) => {
		return post(`/admin/courses/${courseId}/restore`);
	},

	archiveByCourseId: ({ courseId }) => {
		return post(`/admin/courses/${courseId}/archive`);
	},

	unarchiveByCourseId: ({ courseId }) => {
		return del(`/admin/courses/${courseId}/archive`);
	},

	// lessons
	getLessonsByCourseId: ({ courseId }) => {
		return get(`/admin/courses/${courseId}/lessons`, {
		});
	},

	getLessonById: ({ lessonId }) => {
		return get(`/admin/lessons/${lessonId}`, {
		});
	},

	updateLessonById: ({ lesson, lessonId }) => {
		return put(`/admin/lessons/${lessonId}`, {
			requestData: lesson
		});
	},

	deleteLessonById: ({ lessonId }) => {
		return del(`/admin/lessons/${lessonId}`);
	},

	createMediaByLessonId: ({ lessonId, media }) => {
		return post(`/admin/lessons/${lessonId}/media`, {
			requestData: media,
			isFormData: true
		});
	},

	updateMediaByLessonIdAndMediaId: ({ lessonId, mediaId, media }) => {
		return put(`/admin/lessons/${lessonId}/media/${mediaId}`, {
			requestData: media
		});
	},

	deleteMediaByLessonIdAndMediaId: ({ lessonId, mediaId }) => {
		return del(`/admin/lessons/${lessonId}/media/${mediaId}`, {
		});
	},

	reorderLesssonsByCourseId: ({ courseId, lessons }) => {
		return put(`admin/courses/${courseId}/lessons/order`, {
			requestData: lessons
		});
	},

	// activities

	getActivitiesByLessonId: ({ lessonId }) => {
		return get(`/admin/lessons/${lessonId}/activities`, {
		});
	},

	getActivityByIdAndLessonId: ({ lessonId, activityId }) => {
		return get(`/admin/lessons/${lessonId}/activities/${activityId}`, {
		});
	},

	deleteActivityByIdAndLessonId: ({ lessonId, activityId }) => {
		return del(`/admin/lessons/${lessonId}/activities/${activityId}`);
	},

	reorderActivitiesByLessonId: ({ lessonId, activities }) => {
		return put(`/admin/lessons/${lessonId}/activities/order`, {
			requestData: activities
		});
	},

	updateActivityByIdAndLessonId: ({ activity, lessonId, activityId }) => {
		return put(`/admin/lessons/${lessonId}/activities/${activityId}`, {
			requestData: activity
		});
	},

	// focus
	getFocusById: ({ focusId }) => {
		return get(`/admin/courses/focus/${focusId}`, {
		});
	},
	getAllFocuses: () => {
		return get('/admin/courses/focus', {});
	},
	getKeywordsById: ({ focusId }) => {
		return get(`/admin/courses/focus/${focusId}/keywords`, {
		});
	},
	createFocus: ({ focus }) => {
		return post('/admin/courses/focus', {
			requestData: focus
		});
	},
	updateFocusById: ({ focus, focusId }) => {
		return put(`/admin/courses/focus/${focusId}`, {
			requestData: focus
		});
	},
	deleteFocusById: ({ focusId }) => {
		return del(`admin/courses/focus/${focusId}`);
	},

	// Keyword
	getKeywordById: ({ keywordId }) => {
		return get(`/admin/courses/keywords/${keywordId}`, {
		});
	},
	getAllKeywords: () => {
		return get('/admin/courses/keywords', {
		});
	},
	createKeyword: ({ keyword }) => {
		return post('/admin/courses/keywords', {
			requestData: keyword
		});
	},
	updateKeywordById: ({ keyword, keywordId }) => {
		return put(`/admin/courses/keywords/${keywordId}`, {
			requestData: keyword
		});
	},
	deleteKeywordById: ({ keywordId }) => {
		return del(`/admin/courses/keywords/${keywordId}`, {
		});
	},
	addKeywordToFocusByIdAndFocusId: ({ focusId, keywordId }) => {
		return put(`/admin/courses/focus/${focusId}/${keywordId}`);
	},
	removeKeywordFromFocusByIdAndFocusId: ({ focusId, keywordId }) => {
		return del(`/admin/courses/focus/${focusId}/${keywordId}`);
	},

	getStudentsByCourseId: ({
		courseId,
		include,
		searchString,
		page,
		per
	}) => {
		const url = `/admin/courses/${courseId}/students`;
		return get(url, {
			options: {
				include,
				searchString,
				page,
				per
			}
		});
	},

	getAllUsers: ({
		include,
		searchString,
		page,
		per
	}) => {
		return get('/users', {
			options: {
				include,
				searchString,
				page,
				per
			}
		});
	},

	getUserById: ({ userId }) => {
		return get(`/users/${userId}`);
	},

	updateUserById: ({ user, userId }) => {
		return put(`/users/${userId}`, {
			requestData: user
		});
	},

	getTeacherProfileById: ({ profileId }) => {
		return get(`/admin/teacher-profiles/${profileId}`);
	},

	updateTeacherProfileById: ({ teacherProfile, profileId }) => {
		return put(`/admin/teacher-profiles/${profileId}`, {
			requestData: teacherProfile
		});
	},

	createMediaByTeacherProfileId: ({ profileId, media }) => {
		return post(`/admin/teacher-profiles/${profileId}/media`, {
			requestData: media,
			isFormData: true
		});
	},

	createMediaByCourseId: ({ courseId, media }) => {
		return put(`/admin/courses/${courseId}/media`, {
			requestData: media,
			isFormData: true
		});
	},

	deleteMediaByTeacherProfileIdAndMediaId: ({ profileId, mediaId }) => {
		return del(`/admin/teacher-profiles/${profileId}/media/${mediaId}`);
	},

	deleteMediaByIdAndActivityIdAndLessonId: ({ activityId, lessonId, mediaId }) => {
		return del(`/admin/lessons/${lessonId}/activities/${activityId}/media/${mediaId}`);
	},

	createMediaByActivityIdAndLessonId: ({ activityId, lessonId, media }) => {
		return post(`/admin/lessons/${lessonId}/activities/${activityId}/media`, {
			requestData: media,
			isFormData: true
		});
	},

	setUserIsTeacherById: ({ userId }) => {
		return put(`/users/${userId}/is_teacher`);
	},

	enrolStudentInCourse: ({ courseId, studentId }) => {
		return post(`/admin/enrolments/${courseId}/${studentId}`);
	},

	setEnrolmentIsActive: ({ enrolmentId, note }) => {
		return put(`/admin/enrolments/${enrolmentId}/is_active`, { requestData: { note: note } });
	},

	setEnrolmentIsInactive: ({ enrolmentId }) => {
		return del(`/admin/enrolments/${enrolmentId}/is_active`);
	},

	setNoteContentByEnrolmentId: ({ enrolmentId, note }) => {
		return put(`/admin/enrolments/${enrolmentId}/note`, { requestData: { note: note } });
	},

	setUserIsNotTeacherById: ({ userId }) => {
		return del(`/users/${userId}/is_teacher`);
	},

	setUserIsAdminById: ({ userId }) => {
		return put(`/users/${userId}/is_admin`);
	},

	setUserIsNotAdminById: ({ userId }) => {
		return del(`/users/${userId}/is_admin`);
	},

	resetUserPassword: ({ email }) => {
		return post('/users/reset-password', { requestData: { email } });
	},

	getEnrolmentsByUserId: ({
		userId,
		include,
		searchString,
		page,
		per
	}) => {
		return get(`/users/${userId}/enrolments`, {
			options: {
				include,
				searchString,
				page,
				per
			}
		});
	},

	getEnrolmentById: ({ enrolmentId }) => {
		return get(`/admin/enrolments/${enrolmentId}`, {
		});
	},

	getEnrolmentsByTeacherId: ({
		teacherId,
		include,
		searchString,
		page,
		per
	}) => {
		return get(`/admin/teacher/${teacherId}/enrolments`, {
			options: {
				include,
				searchString,
				page,
				per
			}
		});
	},

	getTransactions: ({ teacherId, page, include, per, sortBy, sortDir }) => {
		return get(`/admin/teacher/${teacherId}/transactions`, {
			options: {
				include,
				page,
				per,
				sortBy,
				sortDir
			}
		});
	},

	getUserPayoutInfo: ({ userId }) => {
		return get(`/admin/teacher/${userId}/payouts`);
	},

	setUserPayoutInfo: ({ payout, userId }) => {
		return put(`/admin/teacher/${userId}/payouts`, {
			requestData: payout
		});
	},

	getAllKnowledgebaseCategories: () => {
		return get('/admin/kb/categories');
	},

	getKnowledgebaseCategoryById: ({ categoryId }) => {
		return get(`/admin/kb/categories/${categoryId}`);
	},

	createKnowledgebaseCategory: ({ category }) => {
		return post('/admin/kb/categories', {
			requestData: category
		});
	},

	updateKnowledgebaseCategoryById: ({ category, categoryId }) => {
		return put(`/admin/kb/categories/${categoryId}`, {
			requestData: category
		});
	},

	deleteKnowledgebaseCategoryById: ({ categoryId }) => {
		return del(`/admin/kb/categories/${categoryId}`);
	},

	getAllKnowledgebaseArticles: ({
		include,
		searchString,
		page,
		per
	}) => {
		return get('/admin/kb/articles', {
			options: {
				include,
				searchString,
				page,
				per
			}
		});
	},

	getKnowledgebaseArticleById: ({ articleId }) => {
		return get(`/admin/kb/articles/${articleId}`);
	},

	createKnowledgebaseArticle: ({ article }) => {
		return post('/admin/kb/articles', {
			requestData: article
		});
	},

	updateKnowledgebaseArticleById: ({ article, articleId }) => {
		return put(`/admin/kb/articles/${articleId}`, {
			requestData: article
		});
	},

	deleteKnowledgebaseArticleById: ({ articleId }) => {
		return del(`/admin/kb/articles/${articleId}`);
	}

};
