import { get }      from '@/services/api/methods';
import { post }     from '@/services/api/methods';
import { put }      from '@/services/api/methods';
import { del }      from '@/services/api/methods';

export default {

	getGlossary: ({
		include,
		searchString,
		beginner,
		filters,
		page,
		per,
		sortBy,
		sortDir
	}) => {
		return get('/glossary', {
			options: {
				include,
				searchString,
				beginner,
				filters,
				page,
				per,
				sortBy,
				sortDir
			}
		});
	},

	createTerm: ({ term }) => {
		return post('/admin/glossary', {
			requestData: term
		});
	},

	deleteTerm: ({ termId }) => {
		return del(`/admin/glossary/${termId}`);
	},

	editTerm: ({ termId, term }) => {
		return put(`/admin/glossary/${termId}`, {
			requestData: term
		});
	}

};
